var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kpi-box" }, [
    _c("div", { staticClass: "body" }, [
      _c("table", { staticClass: "table is-striped is-fullwidth is-narrow" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "sub-header" }, [_vm._v("Actual")]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.actuals))]),
              ]),
            ]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.actuals_r4))]),
              ]),
            ]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex bold_ytd_value" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.actuals_fytd))]),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "sub-header" }, [_vm._v("Index")]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.actuals_index))]),
              ]),
            ]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.r4_index))]),
              ]),
            ]),
            _c("td", { staticClass: "data-column text-left" }, [
              _c("div", { staticClass: "inline-flex bold_ytd_value" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.ytd_index))]),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "sub-header" }, [_vm._v("Goal")]),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals))]),
                ]),
              ]
            ),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals_r4)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals_r4))]),
                ]),
              ]
            ),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals_fytd)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex bold_ytd_value" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals_fytd))]),
                ]),
              ]
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "sub-header" }, [_vm._v("Index")]),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals_index)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals_index))]),
                ]),
              ]
            ),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals_r4_index)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals_r4_index))]),
                ]),
              ]
            ),
            _c(
              "td",
              _vm._b(
                { staticClass: "data-column text-left" },
                "td",
                _vm.showMsfrToolTipText(_vm.data.goals_fytd_index)
                  ? { tooltiptext: _vm.msfrToolTipText }
                  : {},
                false
              ),
              [
                _c("div", { staticClass: "inline-flex bold_ytd_value" }, [
                  _c("div", [_vm._v(_vm._s(_vm.data.goals_fytd_index))]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "grey-background" }, [
        _c("th"),
        _c("th", { staticClass: "header" }, [_vm._v("Week")]),
        _c("th", [_vm._v("4 Week")]),
        _c("th", { staticClass: "header" }, [_vm._v("YTD")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }